const listButtons = document.querySelectorAll('.overview-list');
listButtons.forEach((listButton) => {
    const target = listButton.dataset.target;
    const targetElement = document.getElementById(target);

    listButton.addEventListener('click', function(){
        const children = Array.from(listButton.parentNode.children);
        children.forEach((child) => {
            child.classList.remove('active');
        })
        listButton.classList.add('active');

        if (targetElement.classList.contains('row-cols-md-3')){
            targetElement.classList.remove('row-cols-md-3');
            targetElement.classList.add('row-cols-md-1');
        }
    });
})

const columnButtons = document.querySelectorAll('.overview-column');
columnButtons.forEach((columnButton) => {
    const target = columnButton.dataset.target;
    const targetElement = document.getElementById(target);

    columnButton.addEventListener('click', function(){
        const children = Array.from(columnButton.parentNode.children);
        children.forEach((child) => {
            child.classList.remove('active');
        })
        columnButton.classList.add('active');

        if (targetElement.classList.contains('row-cols-md-1')){
            targetElement.classList.remove('row-cols-md-1');
            targetElement.classList.add('row-cols-md-3');
        }
    });
})

