const toggleDropdownMenu = document.querySelectorAll('.link--dropdown');
toggleDropdownMenu.forEach((toggleBtnMenu) => {

    toggleBtnMenu.addEventListener('click', function(){
        const children = Array.from(toggleBtnMenu.parentNode.children);
        children.forEach((child) => {
            child.classList.remove('active');
        })
        toggleBtnMenu.classList.add('active');

        const contentMenuLayer = toggleBtnMenu.nextElementSibling;
        if(contentMenuLayer.classList.contains('content-submenu')) {
            if (contentMenuLayer.classList.contains('show')) {
                contentMenuLayer.classList.remove('show');
                toggleBtnMenu.classList.remove('active');
            }
            else {
                contentMenuLayer.classList.add('show');
            }
        }

    });
})