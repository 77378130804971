const searchButtons = document.querySelectorAll('.icon-search');
searchButtons.forEach((toggleSearch) => {
    const target = toggleSearch.dataset.target;
    const targetElement = document.getElementById(target);

    toggleSearch.addEventListener('click', function(){
        const children = Array.from(toggleSearch.parentNode.children);
        children.forEach((child) => {
            child.classList.remove('active');
        })
        toggleSearch.classList.add('active');

        if (targetElement.classList.contains('show')) {
            targetElement.classList.remove('show');
            toggleSearch.classList.remove('active');
        }
        else {
            targetElement.classList.add('show');
            toggleSearch.classList.add('active');
        }
    });
})


