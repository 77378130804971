const toggleMobile = document.querySelectorAll('.hamburger-menu');

toggleMobile.forEach((toggleButton) => {
    const target = toggleButton.dataset.target;
    const targetElement = document.getElementById(target);

    const pageSlider = document.getElementById('page');

    toggleButton.addEventListener('click', function(){

        if (targetElement.classList.contains('active')) {
            targetElement.classList.remove('active');
            pageSlider.classList.remove('show')
        }
        else {
            targetElement.classList.add('active');
            pageSlider.classList.add('show')
        }
    });
})


