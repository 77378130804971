// Import all of Bootstrap's JS
import * as bootstrap from 'bootstrap'

// or, specify which plugins you need:
import {Popover} from 'bootstrap'

import "./mobile_menu"
import "./mobile_search"
import "./content_menu"
import "./card_overview"
import "./scroll-carousel"

// Create an example popover

document.querySelectorAll('[data-bs-toggle="popover"]')
    .forEach(popover => {
        new Popover(popover);

    });


// Listen for click events on body
document.body.addEventListener('click', function (event) {
    const closeBtnIsClicked = event.target.classList.contains('close-popover');
    if (closeBtnIsClicked) {
        const contentPopover = event.target.closest(".popover"),
            contentId = contentPopover.id,
            trigger = document.querySelector('[aria-describedby=' + contentId + ']');

        // Get a reference to the popover element
        const popoverContent = bootstrap.Popover.getOrCreateInstance(trigger);
        popoverContent.hide()
    }
});