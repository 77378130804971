document.addEventListener("DOMContentLoaded", function () {
    const horizontScrollCarousel = document.querySelectorAll('.horizontal-scroll-carousel');
    horizontScrollCarousel.forEach((carousel) => {
        let carouselWidth = carousel.clientWidth;
        const container = carousel.getElementsByClassName('horizontal-scroll-carousel-inner')[0];
        let containerScrollWidth = container.scrollWidth;
        const column = container.getAttribute('data-column');
        const prevButton = carousel.getElementsByClassName('carousel-control-prev')[0];
        const nextButton = carousel.getElementsByClassName('carousel-control-next')[0];
        let scrollWidth = 0,
            scrollGlobalWidth = 0;

        if (column == 5) {
            scrollGlobalWidth = 230;
        } else {
            scrollGlobalWidth = 270;
        }

        function recheckSlider() {
            carouselWidth = carousel.clientWidth;
            containerScrollWidth = container.scrollWidth;
            if (containerScrollWidth <= carouselWidth) {
                hideControls();
                hideScrollbar();
            } else {
                showControls();
                showScrollbar();
            }
        }

        function showScrollbar() {
            container.style.overflowX = 'scroll';
        }

        function hideScrollbar() {
            container.style.overflowX = 'hidden';
        }

        function hideControls() {
            prevButton.classList.add('disabled');
            nextButton.classList.add('disabled');
        }

        function showControls() {
            prevButton.classList.remove('disabled');
            nextButton.classList.remove('disabled');
        }

        function updateScrollWidth() {
            if (window.innerWidth >= 992) {
                scrollWidth = scrollGlobalWidth * 3;
            } else if (window.innerWidth >= 768) {
                scrollWidth = scrollGlobalWidth * 2;
            } else {
                scrollWidth = scrollGlobalWidth;
            }
        }

        container.addEventListener("scroll", (evt) => {
            if (container.scrollLeft <= 0) {
                prevButton.classList.add('disabled');
            } else {
                prevButton.classList.remove('disabled');
            }

            if (container.scrollLeft >= (container.scrollWidth - carousel.scrollWidth)) {
                nextButton.classList.add('disabled');
            } else {
                nextButton.classList.remove('disabled');
            }
        });

        prevButton.addEventListener('click', function () {
            var scrollAmount = 0;
            var slideTimer = setInterval(function () {
                container.scrollLeft -= 10;
                scrollAmount += 10;

                if (container.scrollLeft <= 0) {
                    prevButton.classList.add('disabled');
                }

                if (container.scrollLeft < (container.scrollWidth - carousel.scrollWidth)) {
                    nextButton.classList.remove('disabled');
                }

                if (scrollAmount >= scrollWidth) {
                    window.clearInterval(slideTimer);
                }
            }, 15);
        });

        nextButton.addEventListener('click', function () {
            var scrollAmount = 0;

            var slideTimer = setInterval(function () {
                container.scrollLeft += 10;
                scrollAmount += 10;

                if (scrollWidth > 0) {
                    prevButton.classList.remove('disabled');
                }

                if (container.scrollLeft >= (container.scrollWidth - carousel.scrollWidth)) {
                    nextButton.classList.add('disabled');
                }

                if (scrollAmount >= scrollWidth) {
                    window.clearInterval(slideTimer);
                }
            }, 15);
        });

        updateScrollWidth();
        recheckSlider();

        window.addEventListener("resize", function () {
            updateScrollWidth();
            recheckSlider();
        });
    })
})